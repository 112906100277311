import * as React from 'react'
import {RootLayout as Layout} from '../Layout'
import { graphql } from "gatsby"

function PageTemplate({data}) {
    const {markdownRemark}=data
    return (
        <Layout title={markdownRemark.frontmatter.title}>
            <div className={`md-content-wrap  ${markdownRemark.frontmatter.pageClassName}`}>
                <div dangerouslySetInnerHTML={{__html: markdownRemark.html}}/>
            </div>
        </Layout>

    )
}
export const pageQuery = graphql`
query BlogPostQuery($id: String, $language: String) {
  markdownRemark(id: {eq: $id}) {
    id
    frontmatter {
      title
      root
    }
    html
  }
  locales: allLocale(
    filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}
  ) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`
export default PageTemplate
